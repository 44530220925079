.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  min-height: 100vh;
  // opacity: 0;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  // animation: fadeIn 0.5s forwards;
  // animation-delay: 0.5s;
}

.about-page {
  background-color: rgba(5, 106, 76, 0.44);
  color: #333333;
  justify-content: space-around ;
  overflow: auto;
}

.contact-page {
  background-color: rgba(17, 82, 119, 0.63);
  color: #ffffff;
}

.projects-page {
  background-color: #909090;
  color: #333333;
}

.about-page,
.projects-page,
.contact-page {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .text-zone {
    width: 38%;
    max-height: 90%;
    

    h1 {
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.326);
      font-size: 70px;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      font-family: "Ibarra Real Nova", serif;
    }

    p {
      font-size: 22px;
      font-family: "Smooch Sans", sans-serif;
      font-weight: 600;
      letter-spacing: 1.3px;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.25s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .about-page,
  .projects-page,
  .contact-page {
    flex-direction: column;
    .text-zone {
      width: 84%;
      text-align: center;

      h1 {
        font-size: 65px;
        margin-bottom: 20px;
      }
    }
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .text-zone {
    h1 {
      font-size: 70px;
    }
  }
}
