@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova&family=Smooch+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans&family=Source+Code+Pro&display=swap');

input,
textarea {
  font-family: 'Ibarra Real Nova', serif;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;

}