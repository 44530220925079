.nav-bar {
    position: absolute; //!so page class goes next to it
    background-color: #181818;
    width: 70px;
    z-index: 3;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    nav {
        text-align: center;
        height: 210px;
        width: 100%;
    
        a {
            font-size: 22px;
            display: block;
            line-height: 51px;
            color: #4d4d4e;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
      
            i {
              transition: all 0.3s ease-out;
            }

            &:hover {
              color: #a43838;
              transform: scale(1.2);
      
            }
      
            &.active {
              svg {
                color: #a43838;
              }
            }
          }
    }

    ul {
        width: 100%;
        padding: 0;
        list-style: none;
        text-align: center;
        margin-bottom: 15px;
    
        li {
          a {
            padding: 7px 0;
            display: block;
            font-size: 15px;
            line-height: 16px;
    
            &:hover svg {
              color: #a43838;
              transform: scale(1.2);
    
            }
          }
        }
      }

      .logo-small {
        display: block;
        padding: 8px 0;
      
        img {
          display: block;
          margin: 8px auto;
          width: 24px;
          height: auto;
          transition: all 0.3s ease-in-out;
      
          &:hover {
            transform: scale(1.2);
          }
        }
      }
}

.nav-bar-mobile {
    display: none;
}


@media only screen and (max-width: 480px) {
    .nav-bar {
      display: none;
    }
  
    .nav-bar-mobile {
      display: block;
      z-index: 3;
      background: #181818;
      height: 65px;
    }
  
    .logo-small {
      img {
        height: 40px;
        position: absolute;
        left: 25px;
        width: 30px;
        top: 12px;
      }
    }
  }
  