.logo-container {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  svg {
    width: 100%;
    height: 100%;
    bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
  .logo-container {
    right: 0%;
    left: 0%;
  }
}

@media only screen and (max-width: 385px) {
  .logo-container {
    width: 365px
  }
}